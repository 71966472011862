import React from 'react';
import {Outlet, Route, Routes, useLoaderData} from "react-router-dom";
import {PageLink, PageTitle} from "../../../_mesomb/layout/core";
import i18n from "../../../_mesomb/i18n";
import {useTranslation} from "react-i18next";
import Products from "./components/Products.tsx";
import product from "./components/Product.tsx";
import Product from "./components/Product.tsx";
import SentryRoutes from "../../../_mesomb/helpers/SentryRoutes.ts";

const breadcrumbs: Array<PageLink> = [
  {
    title: i18n.t('Products'),
    path: '/products/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function ProductsPage() {
  const {t} = useTranslation()
  const {product} = useLoaderData()
  return (
    <SentryRoutes>
      <Route element={<Outlet />}>
        <Route
          path=''
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>{t('Products')}</PageTitle>
              <Products />
            </>
          }
        />
        <Route
          path=':id/'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>{t('Product')}</PageTitle>
              <Product product={product} />
            </>
          }
        />
      </Route>
    </SentryRoutes>
  );
}

export default ProductsPage;
