import React, {useMemo} from 'react';
import {toast} from "react-toastify";
import ModalFormBuilder from "../../../../_mesomb/partials/components/ModalFormBuilder.tsx";
import I18n from "../../../../_mesomb/i18n";
import * as Yup from "yup";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

function ModalProductForm({show, onClose, selected, onFinish}) {
  const {t} = useTranslation()
  const fields = useMemo(() => [
    {
      type: 'text',
      name: 'name',
      label: t('Name'),
      help: t('Product_name_help'),
      placeholder: 'Premium Plan, sunglasses, etc.'
    },
    {
      type: 'textarea',
      name: 'description',
      label: t('Description'),
      help: t('Product_description_help')
    },
    {
      name: 'image',
      label: I18n.t('Image'),
      type: 'dropzone',
      required: false,
      help: t('Product_image_help'),
      col: 2,
      placeholder: t('Upload image')
    },
    {
      type: 'number',
      name: 'default_price',
      label: t('Price'),
      placeholder: '0.0'
      // help: t('Product_price_help')
    },
    {
      name: 'price_type',
      label: I18n.t('Price Type'),
      type: 'radio',
      options: [
        {value: 'OneTime', label: t('One Time')},
        {value: 'Recurring', label: t('Recurring')},
      ],
      help: t('Product_price_type_help'),
      orientation: 'horizontal'
    },
    {
      name: 'price_interval',
      label: I18n.t('Price Interval'),
      type: 'select',
      options: [
        {value: 'daily', label: t('Daily')},
        {value: 'weekly', label: t('Weekly')},
        {value: 'monthly', label: t('Monthly')},
        {value: 'yearly', label: t('Yearly')},
      ]
    },
  ], []);
  const formSchema = useMemo(() => (Yup.object().shape({
    name: Yup.string().required(t('Field_is_required')),
    description: Yup.string(),
    default_price: Yup.number(),
    price_type: Yup.string().required(t('Field_is_required')),
    // b_party: Yup.string().matches(/^[0-9]{8,9}$/, t('Invalid_phone_number')).required(t('Field_is_required')),
  })), [])
  return (
    <ModalFormBuilder
      show={show}
      title={selected ? t('Edit Product') : t('New Product')}
      fields={fields}
      nbrCols={2}
      formSchema={formSchema}
      handleClose={onClose}
      action={{
        path: selected ? `/products/${selected.id}/` : `/products/`,
        method: selected ? 'patch' : 'post'
      }}
      onSubmit={(data) => {
        onFinish && onFinish(data)
        // setShowModal(false);
        // setSelected(false)
        // // queryClient.invalidateQueries([`${QUERIES.PRODUCT_LIST}-${query}`])
        // toast.info(selected ? t('Product successfully updated') : t('Product successfully created'))
        // ref.current?.click()
        // onClose && onClose()
      }}
      initialValues={selected ? {
        name: selected.name,
        description: selected.description,
        default_price: selected.default_price,
        price_type: selected.price_type,
        price_interval: selected.price_interval,
        image: selected.image,
      } : {price_type: 'OneTime'}}
    />
  );
}

export default ModalProductForm;
